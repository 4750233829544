import React, {useState} from 'react';
import { motion } from 'framer-motion';
import Parser from 'html-react-parser';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/scss/modal-video.scss';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Main1({ image, title, description, url, button, background, video }) {
    const [isOpen, setOpen] = useState(false);
    const bgStyle = background ? { background } : {};
    return (
            <section className="about-section-two style-two layout-pt-60 layout-pb-60 js-tab-menu-content" style={bgStyle}>
                <div className="auto-container">
                    <div className="row grid-base align-items-center">
                        <div className="image-column -no-margin col-xl-5 col-lg-6 col-md-12 col-sm-12 wow fadeInRight">
                            {video && image? (
                                    <div className="video-outer">
                                        <div className="video-box">
                                            <figure className="image stand">
                                                <a href={`https://www.youtube.com/watch?v=${video}&autoplay=1`}
                                                   className="play-now" onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpen(true);
                                                }}>
                                                    <LazyLoadImage
                                                        src={image.urlS3}
                                                        alt="video"
                                                        effect="blur"
                                                        className="homepage"
                                                    />
                                                    <i className="icon flaticon-play-vert" aria-hidden="true"/>
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                            ) :video ? (
                                    <div className="video-outer">
                                        <div className="video-box">
                                            <figure className="image stand">
                                                <a href={`https://www.youtube.com/watch?v=${video}&autoplay=1`}
                                                   className="play-now" onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpen(true);
                                                }}>
                                                    <LazyLoadImage src={`https://img.youtube.com/vi/${video}/hqdefault.jpg`} alt="video" effect="blur" className="homepage"/>
                                                    <i className="icon flaticon-play-vert" aria-hidden="true" />
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                            ) : (
                                    <figure className="image-box">
                                        {image && <LazyLoadImage src={image.urlS3} alt={image.name} effect="blur" style= {{borderRadius: "25px"}}/>}
                                    </figure>
                            )}
                        </div>
                        <ModalVideo
                                channel="youtube"
                                autoplay
                                isOpen={isOpen}
                                videoId={video}
                                onClose={() => setOpen(false)}
                        />

                        <div className="content-column col-xl-5 offset-xl-2 col-lg-5 offset-lg-1 col-md-12 col-sm-12">
                            <div className="wow fadeInLeft">
                                <div className="sec-title">
                                    {title && (<h2 className="fw-700">{typeof title === 'string' ? Parser(title) : ''}</h2>)}
                                </div>
                                <div className="text">
                                    {description && (<div className="text mt-30">{typeof description === 'string' ? Parser(description) : ''}</div>)}
                                </div>
                                {button &&
                                    <div className="btn-container mt-30">
                                        <motion.a
                                            href={url}
                                            className="theme-btn btn-style-six"
                                            // initial={{opacity: 0, y: 0}}
                                            // animate={{opacity: 1, y: 20}}
                                            // transition={{delay: 1.0, duration: 1.5}}
                                        >
                                            {button}
                                        </motion.a>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Main1;
