import React, {useEffect, useState} from "react";
import Seo from "../components/common/Seo";
import FormModel from "../components/form/FormModel";
import MobileMenu from "../components/header/MobileMenu";
import Header from "../components/home-7/Header";
import FooterDefault from "../components/footer/common-footer";
import axios from "axios";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Parser from "html-react-parser";

function ContactPage() {

    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/front_pages?type=7');
                setPages(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const getRandomImage = (images) => {
        const randomIndex = Math.floor(Math.random() * images.length);
        return images[randomIndex];
    };

    const handleClose = () => {
        console.log('Form closed or submission finished');
    };

  return (
    <>
        <Seo pageTitle={pages[0]?.titre} pageDescription={pages[0]?.description} pageUrl={window.location.href} />
        <span className="header-span"></span>
        <Header />
        <MobileMenu />
        {!loading && (
        <section className="contact-section">
            {pages?.map((home, homeIndex) => (
                <React.Fragment key={homeIndex}>
                    {home?.frontPageContainers?.map((container, containerIndex) => (
                        <div key={containerIndex}
                             className="contact-section-container">
                            {container?.libre && (
                                <React.Fragment key={`banner-${containerIndex}`}>
                                    {container.frontPageContents?.map((content, contentIndex) => (
                                        <div key={contentIndex} className="d-flex justify-content-between">
                                            {content?.image?.length > 0 ? (
                                                <LazyLoadImage
                                                    src={getRandomImage(content.image).urlS3}
                                                    alt=""
                                                    effect="blur"
                                                />
                                            ) : ''}
                                            <div className="contact-form default-form">
                                                {content.titre &&<h2 className="shadow-title center">{Parser(content.titre)}</h2>}
                                                {content.description &&<div className="text text-center mt-9">{Parser(content.description)}</div>}
                                                <div className="mb-5"></div>
                                                <FormModel type={1} handleClose={handleClose} />
                                            </div>
                                        </div>
                                    ))}
                                </React.Fragment>
                            )}
                        </div>
                    ))}
                </React.Fragment>
            ))}
        </section>
        )}
        <FooterDefault footerStyle="style-six alternate -type-11" />
    </>
  );
}

export default ContactPage;
