import Social from "./Social";
import {Link} from "react-router-dom";

const CopyrightFooter = () => {
  return (
    <div className="footer-bottom">
      <div className="auto-container">
        <div className="outer-box">
          <div className="copyright-text outer-box">
            <Link
              to="https://www.groupe-talentshandicap.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="padding-left"
            >© {new Date().getFullYear()} Groupe Talents Handicap. Tous droits réservés
            </Link>
            <Link
                to="/mentions-legales"
                rel="noopener noreferrer"
                className="padding-left"
            >
              Mentions légales
            </Link>
            <Link
                to="/cgu"
                rel="noopener noreferrer"
                className="padding-left"
            >
              Conditions générales d'utilisation
            </Link>
            <Link
                to="/politique-confidentialite-donnees"
                rel="noopener noreferrer"
                className="padding-left"
            >
              Politique de confidentialité des données
            </Link>
          </div>
          <div className="social-links">
            <Social />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyrightFooter;
