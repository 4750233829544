import {useEffect} from 'react';

const GoogleAnalytics = () => {
    useEffect(() => {
        const scriptTag = document.createElement("script");
        scriptTag.async = true;
        scriptTag.src = "https://www.googletagmanager.com/gtag/js?id=G-9JYENYCB27";
        document.head.appendChild(scriptTag);

        const scriptInline = document.createElement("script");
        scriptInline.innerHTML = `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-9JYENYCB27');
        `;
        document.head.appendChild(scriptInline);

        return () => {
            document.head.removeChild(scriptTag);
            document.head.removeChild(scriptInline);
        };
    }, []);

    return null;
};

export default GoogleAnalytics;
