import React from 'react';
import {Link} from "react-router-dom";

const SidebarHeader = ({onClose}) => {
  return (
    <div className="pro-header">
      <Link to="/">
        <img src="/images/Logo-THF-web.png" alt="Logo Talents Handicap formation" />
      </Link>
      {/* End logo */}

      <div className="fix-icon" data-bs-dismiss="offcanvas" aria-label="Close" onClick={onClose}>
        <span className="flaticon-close"></span>
      </div>
      {/* icon close */}
    </div>
  );
};

export default SidebarHeader;
