import React, { useState, useEffect } from 'react';
import { AnimatePresence } from "framer-motion";
import CardSkeleton from "../CardSkeleton";
import SessionCard from "../SessionCard";

export default function SessionFilterTab ({sessions}){

    const [filterData, setFilterData] = useState(sessions);
    /*const [search, setSearch] = useState("");
    const [filter, setFilter] = useState("");*/

    useEffect(() => {
        setFilterData(sessions);
    }, [sessions]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    /*const handleSearch = (e) => {
        setSearch(e.target.value);
        if (e.target.value === "") {
            setFilterData(sessions);
        } else {
            const filtered = sessions?.filter((session) =>
                    session.titre.toLowerCase().includes(search.toLowerCase())
            );
            setFilterData(filtered);
        }
    };*/


    return(
        <>
            {/*<div className="flex justify-center items-center mt-4">
                <input
                        type="text"
                        placeholder="Rechercher une formation"
                        onChange={(e) => handleSearch(e)}
                        name="search"
                        className="border-2 border-gray-300 p-2 rounded-md max-w-xs w-full"
                />
            </div>*/}

            <div className="content-column col-lg-12">
                <div className="ls-outer">
                    <div className="row flex-column">
                        {filterData?.length > 0 ? (
                                    <div className="row flex-column">
                                        {filterData.map((session, sessionIndex) => (
                                            <div key={sessionIndex}>
                                                {session.mois.map((item, itemIndex) => (
                                                    <div key={itemIndex} className="session-timeline">
                                                        <div className="shadow-title left">{item.mois} {session.annee}</div>
                                                        <div className="row">
                                                            {item[`${item.mois}`].map((data, dataIndex) => (
                                                                <AnimatePresence key={dataIndex}>
                                                                    {loading ? (
                                                                        <CardSkeleton />
                                                                    ) : (
                                                                        <SessionCard session={data} />
                                                                    )}
                                                                </AnimatePresence>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="no-sessions">

                                        <div className="shadow-title">Aucune date disponible pour le moment.</div>
                                        <div className="text mt-15">
                                            Nous travaillons activement à programmer de <span>nouvelles sessions inter-entreprises.</span>
                                            N'hésitez pas à <span>nous contacter</span> pour connaître les prochaines dates ou pour <span>toute demande spécifique.</span> <br />
                                            Découvrez toutes nos formations pour explorer l'ensemble de nos solutions adaptées à vos besoins.
                                        </div>
                                        <div className="d-flex justify-content-around mt-30">
                                            <a href="/contact" class="theme-btn btn-style-six small">Contactez-nous</a>
                                            <a href="/nos-formations" class="theme-btn btn-style-six small">Voir toutes nos formations</a>
                                        </div>
                                    </div>
                                )}
                    </div>
                </div>
            </div>

        </>
    )
}

