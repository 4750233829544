import React, { useEffect, useState } from 'react';
import Parser from 'html-react-parser';
import Breadcrumb from "../../common/Breadcrumb";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function Hero8({ content, BreadcrumbVisible = false, titleBreadcrumb, homepage = false }) {
    const [rating, setRating] = useState(null);

    useEffect(() => {
        fetch('https://gestion.talentshandicap-formation.fr/api/allformations/score')
                .then((response) => response.json())
                .then((data) => {
                    if (data?.note) {
                        setRating(data.note);
                    }
                })
                .catch((error) => console.error('Erreur lors de la récupération du score:', error));
    }, []);

    const generateStars = (note) => {
        const fullStars = Math.floor(note);
        const partialStarFill = note % 1;
        const emptyStars = 5 - fullStars - (partialStarFill > 0 ? 1 : 0);
        const starColor = '#b0f7af';

        return (
                <>
                    {Array(fullStars).fill().map((_, index) => (
                            <span key={`full-${index}`} style={{ color: starColor }}>★</span>
                    ))}
                    {partialStarFill > 0 && partialStarFill < 1 && (
                            <span
                                    key="partial-star"
                                    style={{
                                        position: 'relative',
                                        color: '#ccc'
                                    }}
                            >
                    <span
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                overflow: 'hidden',
                                width: `${partialStarFill * 100}%`,
                                color: starColor
                            }}
                    >
                        ★
                    </span>
                    ☆
                </span>
                    )}
                    {Array(emptyStars).fill().map((_, index) => (
                            <span key={`empty-${index}`} style={{ color: '#ccc' }}>☆</span>
                    ))}
                </>
        );
    };

    return (
            <section className="banner-section-three -type-12">
                <div className="bg-image">
                    <figure className="main-image wow fadeInRight" data-wow-delay="1500ms">
                        {content?.image[0] && (
                                <LazyLoadImage
                                        src={content.image[0].urlS3}
                                        alt={content.image[0].nom}
                                        effect="blur"
                                        width="100%"
                                        height="auto"
                                />
                        )}
                    </figure>
                    <div className="image-voile" style={{ display: "none" }}></div>
                </div>

                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-9 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="title-box wow fadeInUp">
                                    {BreadcrumbVisible && <Breadcrumb meta={titleBreadcrumb} />}
                                    <h1>
                                        {typeof content?.titre === 'string' ? Parser(content.titre) : ''}
                                    </h1>
                                    <div className="text">
                                        {typeof content?.description === 'string' ? Parser(content.description) : ''}
                                    </div>
                                </div>

                                {homepage &&
                                    <div className="notation">
                                        <div className="rating-container">
                                            <span className="rating-title">Satisfaction globale de nos formations</span>
                                            <div className="rating-wrapper">
                                                <div className="rating">
                                                    <div className="circle">
                                                        {rating !== null ? rating.toFixed(1) : '...'}
                                                    </div>
                                                    <div className="stars">
                                                        {rating !== null ? generateStars(rating) : '...'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="rating-asterisk">
                                                <span className="rating-asterisk-text">
                                                    * Note issue des évaluations de satisfaction des formations
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {content?.button && (
                                    <a href={content.url} className="theme-btn btn-style-six mt-3">
                                        {content.button}
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Hero8;
