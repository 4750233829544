import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import defaultImages from "../images/DefaultImages";
import {cleanAndTruncateText} from "../../utils/wordsLimit";

export default function FonctionnaliteCard({ fonctionnalites, containerIndex }) {
    const getRandomImage = () => {
        const randomIndex = Math.floor(Math.random() * defaultImages.length);
        return defaultImages[randomIndex];
    };

    return (
            <div className="row grid-base pt-50 py-50 wow fadeInUp" key={`fonctionnalites-${containerIndex}`}>
                {fonctionnalites?.slice(0, 8).map((product) => (
                    product.titre && product.description ? (
                        <AnimatePresence key={product.id}>
                            <motion.div
                                className="col-lg-3 col-md-6 col-sm-12 no-margin"
                                layout
                                initial={{ opacity: 0.6, y: 50 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.25, ease: "easeInOut" }}
                                whileHover={{ y: -10, boxShadow: "0px 7px 18px rgba(64, 79, 104, 0.05)"}}
                            >
                                <div className="work-block -type-4">
                                    <figure className="icon-wrap">
                                        {product?.image?.length > 0 ? (
                                            product.image.slice(0, 1).map(img => <img key={img.id} src={img.urlS3} alt="illustration" />)
                                        ) : (
                                            <img src={getRandomImage()} alt="illustration" />
                                        )}
                                    </figure>
                                </div>
                                <h5 className="title" style={{ textAlign: 'center', fontWeight: '700'}}>{cleanAndTruncateText(product.titre, 50, 1)}</h5>
                                <p className="" style={{ textAlign: 'center'}}>{cleanAndTruncateText(product.description, 120)}</p>
                            </motion.div>
                        </AnimatePresence>
                    ) : null
                ))}
            </div>
    );
}
