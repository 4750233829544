import React from 'react';
import Parser from 'html-react-parser';

function Libre({title, description, index, background}) {
    const bgStyle = background ? { background } : {};
    return (
            <section className="libre layout-pt-20 layout-pb-20" style={bgStyle}  key={index}>
                <div className="auto-container">
                    <div className="text-center">
                        {title ? (
                        <h2 className="libre-title">
                            {Parser(title)}
                        </h2>
                        ) : null}
                        {description ? (
                        <div className="text libre-text">
                            {Parser(description)}
                        </div>
                        ) : null}
                    </div>
                </div>
            </section>
        );
}

export default Libre;
