import React from "react";
import { motion } from "framer-motion";
import {Link} from "react-router-dom";
import Parser from 'html-react-parser';
import {truncateText} from "../utils/wordsLimit";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export default function ProductCard({ product, colLg }) {
    const formationId = `${product.id}`;
    const formationSlug = `${product.slug}`;

    let truncatedTitle = truncateText(product.titre, 60, 12, 2);
    //let truncatedTitle = (colLg === '3') ? truncateText(product.titre, 50, 8, 2) : truncateText(product.titre, 60, 12, 2);
    let truncatedIntroduction = truncateText(product.introduction, 200, 23 , 2);
   //let truncatedIntroduction = (colLg === '3') ? truncateText(product.introduction, 200, 27, 2) : truncateText(product.titre, 200, 23, 2);
    if (colLg === '3'){
        truncatedTitle = truncateText(product.titre, 50, 9, 2);
        truncatedIntroduction = truncateText(product.introduction, 200, 27 , 2);
    }

    return (
            <motion.div
                    className={`news-block col-lg-${colLg} col-md-6 col-sm-12`}
                    // style={{padding: "0 35px"}}
                    layout
                    initial={{ opacity: 0.6, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.4 }}
            >
                    <div className="news-block-container">
                        <div className="image-box">
                            <Link to={`/nos-formations/${formationId}/${formationSlug}`} title={product.titre}>
                                <figure className="image col4">
                                    {product?.image?.length > 0 ? (
                                            product.image.slice(0, 1).map(img => <img key={img.id} src={img.urlS3} alt={img.alt} />)
                                    ) : (
                                        <LazyLoadImage
                                            src={product.oneVignette.lien}
                                            alt={product.oneVignette.alt}
                                            effect="blur"
                                        />
                                    )}
                                </figure>
                            </Link>
                        </div>
                        <div className="lower-content">
                            <h3>
                                <Link to={`/nos-formations/${formationId}/${formationSlug}`} title={product.titre}>
                                    {truncatedTitle && Parser(truncatedTitle)}
                                </Link>
                            </h3>
                            <div style={{marginBottom: "10px"}}>
                                {truncatedIntroduction && Parser(truncatedIntroduction)}
                            </div>
                            <a href={`/nos-formations/${formationId}/${formationSlug}`} className="read-more"><i className="fa fa-angle-right"></i>Découvrir</a>
                        </div>
                    </div>
            </motion.div>
    );
}
